import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PaginaInical from "../src/pages/paginainicial";
import PaginaNome from "../src/pages/nome"; // Importe o componente da próxima página
import Perguntas from "./pages/perguntas";
import NomeContext from "./context/nome";
const App = () => {
  const [nome, setNome] = useState('');
  return (
    <NomeContext.Provider value={{ nome, setNome }}>
      <Router>
        <Routes>
          <Route path="/" element={<PaginaInical />}></Route>
          <Route path="/nome" element={<PaginaNome />}></Route>
          <Route path="/perguntas" element={<Perguntas />}></Route>
        </Routes>
      </Router>
    </NomeContext.Provider>
  );
};

export default App;

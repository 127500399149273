import React, { useContext } from "react";
import NomeContext from "../context/nome";
import analista from "../asstes/analista.png";
import comunicador from "../asstes/comunicador.png";
import executores from "../asstes/executador.png";
import planejadores from "../asstes/planejador1.png";
const MostrarResultado = ({ resultado }) => {
  const { nome } = useContext(NomeContext);
  return (
    <div className="body">
      <p className="frame5 alicerce">
        ALICERCE<span className="cor3">3</span>
      </p>

      <div className="frame10">
        <div className="tituloNome">
          <h1>
            Obrigado, {nome}.Por responder ao questionário. Aqui está o seu
            resultado:
          </h1>
        </div>
        <div className="frame11">
          <div className="titulo">
            {" "}
            <h1>
              {" "}
              Dominante/Executador:{" "}
              <span className="spanExecutor">{resultado.a}%</span>{" "}
            </h1>{" "}
          </div>
          <img className="tamanhoImg" src={executores} alt="executador" />
          <div className="descricao">
            <p>
              O perfil comportamental Dominante/Executador é caracterizado por
              assertividade e orientação para resultados. Eles são decisivos,
              diretos e gostam de assumir a liderança. Valorizam a eficiência e
              são motivados por desafios e competição. No entanto, podem ser
              percebidos como impacientes ou insensíveis devido à sua abordagem
              direta e focada em metas.
            </p>
          </div>
        </div>
        <div className="frame11">
          <div className="titulo">
            {" "}
            <h1>
              {" "}
              Extrovertido/Comunicador:{" "}
              <span className="spanComunicador">{resultado.b}%</span>
            </h1>
          </div>
          <img className="tamanhoImg" src={comunicador} alt="executador" />
          <div className="descricao">
            <p>
              O perfil comportamental Extrovertido/Comunicador é marcado por
              sociabilidade e entusiasmo. Eles são expressivos, persuasivos e
              gostam de interagir com os outros. Valorizam a colaboração e são
              motivados por reconhecimento social e interações positivas. No
              entanto, podem ser percebidos como superficiais ou impulsivos
              devido à sua abordagem extrovertida e focada na comunicação.
            </p>
          </div>
        </div>
        <div className="frame11">
          <div className="titulo">
            <h1>
              {" "}
              Paciente/Planejador:{" "}
              <span className="spanPlanejador">{resultado.c}%</span>
            </h1>
          </div>

          <img className="tamanhoImg" src={planejadores} alt="executador" />
          <div className="descricao">
            <p>
              O perfil comportamental Paciente/Planejador é caracterizado por
              uma abordagem calma e metódica. Eles são organizados, confiáveis e
              preferem seguir planos estabelecidos. Valorizam a estabilidade e
              são motivados por harmonia e cooperação. No entanto, podem ser
              percebidos como resistentes à mudança ou indecisos devido à sua
              preferência por rotinas e planejamento.
            </p>
          </div>
        </div>
        <div className="frame11">
          <div className="titulo">
            <h1>
              {" "}
              Analista: <span className="spanAnalista">
                {resultado.d}%
              </span>{" "}
            </h1>
          </div>
          <img className="tamanhoImg" src={analista} alt="executador" />
          <div className="descricao">
            <p>
              O perfil comportamental do Analista é caracterizado por uma
              abordagem metódica e detalhista. Eles são precisos, lógicos e
              valorizam a qualidade do trabalho. Excelentes em coletar e
              analisar informações, os Analistas são motivados por desafios
              intelectuais e têm um alto padrão de desempenho. No entanto, podem
              ser percebidos como distantes ou críticos devido à sua atenção aos
              detalhes e desejo de precisão.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostrarResultado;

const perguntas = [
	[
		{ label: "Determinado", value: "a" },
		{ label: "Confiante", value: "b" },
		{ label: "Persistente", value: "c" },
		{ label: "Preciso", value: "d" },
	],
	[
		{ label: "Apressado", value: "a" },
		{ label: "Persuasivo", value: "b" },
		{ label: "Metódico", value: "c" },
		{ label: "Cuidadoso", value: "d" },
	],
	[
		{ label: "Competitivo", value: "a" },
		{ label: "Político", value: "b" },
		{ label: "Cooperativo", value: "c" },
		{ label: "Diplomata", value: "d" },
	],
	[
		{ label: "Objetivo", value: "a" },
		{ label: "Exagerado", value: "b" },
		{ label: "Estável", value: "c" },
		{ label: "Exato", value: "d" },
	],
	[
		{ label: "Assertivo", value: "a" },
		{ label: "Otimista", value: "b" },
		{ label: "Paciente", value: "c" },
		{ label: "Prudente", value: "d" },
	],
	[
		{ label: "Fazedor", value: "a" },
		{ label: "Inspirador", value: "b" },
		{ label: "Persistente", value: "c" },
		{ label: "Perfeccionista", value: "d" },
	],
	[
		{ label: "Agressivo", value: "a" },
		{ label: "Expansivo", value: "b" },
		{ label: "Possessivo", value: "c" },
		{ label: "Julgador", value: "d" },
	],
	[
		{ label: "Decidido", value: "a" },
		{ label: "Flexível", value: "b" },
		{ label: "Prevísivel", value: "c" },
		{ label: "Sistemático", value: "d" },
	],
	[
		{ label: "Inovador", value: "a" },
		{ label: "Comunicativo", value: "b" },
		{ label: "Agradável", value: "c" },
		{ label: "Elegante", value: "d" },
	],
	[
		{ label: "Autoritário", value: "a" },
		{ label: "Extravagante", value: "b" },
		{ label: "Modesto", value: "c" },
		{ label: "Desconfiado", value: "d" },
	],
	[
		{ label: "Enérgico", value: "a" },
		{ label: "Entusiasmado", value: "b" },
		{ label: "Calmo", value: "c" },
		{ label: "Disciplinado", value: "d" },
	],
	[
		{ label: "Firme", value: "a" },
		{ label: "Expressivo", value: "b" },
		{ label: "Amável", value: "c" },
		{ label: "Formal", value: "d" },
	],
	[
		{ label: "Visionário", value: "a" },
		{ label: "Criativo", value: "b" },
		{ label: "Ponderado", value: "c" },
		{ label: "Detalhista", value: "d" },
	],
	[
		{ label: "Egocêntrico", value: "a" },
		{ label: "Tagarela", value: "b" },
		{ label: "Acomadado", value: "c" },
		{ label: "Retraído", value: "d" },
	],
	[
		{ label: "Inspira confiança", value: "a" },
		{ label: "Convincente", value: "b" },
		{ label: "Compreensivo", value: "c" },
		{ label: "Pontual", value: "d" },
	],
	[
		{ label: "Intimidade", value: "a" },
		{ label: "Sem cerimônia", value: "b" },
		{ label: "Reservado", value: "c" },
		{ label: "Preocupante", value: "d" },
	],
	[
		{ label: "Vigoroso", value: "a" },
		{ label: "Caloroso", value: "b" },
		{ label: "Gentil", value: "c" },
		{ label: "Preocupado", value: "d" },
	],
	[
		{ label: "Ousado", value: "a" },
		{ label: "Sedutor", value: "b" },
		{ label: "Harmonizador", value: "c" },
		{ label: "Cauteloso", value: "d" },
	],
	[
		{ label: "Força de vontade", value: "a" },
		{ label: "Espontâneo", value: "b" },
		{ label: "Satisfeito", value: "c" },
		{ label: "Conservador", value: "d" },
	],
	[
		{ label: "Exigente", value: "a" },
		{ label: "Social", value: "b" },
		{ label: "Leal", value: "c" },
		{ label: "Rigoroso", value: "d" },
	],
	[
		{ label: "Pioneiro", value: "a" },
		{ label: "Divertido", value: "b" },
		{ label: "Tranquilo", value: "c" },
		{ label: "Convencional", value: "d" },
	],
	[
		{ label: "Ambicioso", value: "a" },
		{ label: "Radiante", value: "b" },
		{ label: "Regulado", value: "c" },
		{ label: "Calculista", value: "d" },
	],
	[
		{ label: "Inquisitivo", value: "a" },
		{ label: "Oferecido", value: "b" },
		{ label: "Rigido consigo", value: "c" },
		{ label: "Cético", value: "d" },
	],
	[
		{ label: "Audacioso", value: "a" },
		{ label: "Extrovertido", value: "b" },
		{ label: "Casual", value: "c" },
		{ label: "Meticuloso", value: "d" },
	],
	[
		{ label: "Direto", value: "a" },
		{ label: "Prolixo", value: "b" },
		{ label: "Moderado", value: "c" },
		{ label: "Processual", value: "d" },
	],
];

export default perguntas;

const embaralharOpcoes = (opcoes) => {
    const opcoesEmbaralhadas = [...opcoes];
    for (let i = opcoesEmbaralhadas.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [opcoesEmbaralhadas[i], opcoesEmbaralhadas[j]] = [opcoesEmbaralhadas[j], opcoesEmbaralhadas[i]];
    }
    return opcoesEmbaralhadas;
};


export default embaralharOpcoes
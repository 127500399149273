import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/nome.css";

const PaginaInical = () => {
  const navigate = useNavigate();

  const handleComecarClick = () => {
    navigate("/nome");
  };

  return (
    <div className="body">
      <p className="frame5 alicerce">
        ALICERCE<span className="cor3">3</span>
      </p>

      <div className="frame2 text1">
        <p>Teste de Perfil Comportamental</p>
        <div className="frame4">
          <button onClick={handleComecarClick} className="but inicial">
            Começar
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaginaInical;

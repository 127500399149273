import React, { useState, useEffect } from "react";
import "../styles/nome.css";
import perguntas from "../utils/listaDePerguntas";
import embaralharOpcoes from "../utils/embaralharOpcoes";
import MostrarResultado from "../utils/mostrarResultado";
const Perguntas = () => {
	const [perguntaAtual, setPerguntaAtual] = useState(1);
	const [respostaSelecionada, setRespostaSelecionada] = useState(null);
	const [resultados, setResultados] = useState({ a: 0, b: 0, c: 0, d: 0 });
	const [opcoesEmbaralhadas, setOpcoesEmbaralhadas] = useState([]);
	const [todasPerguntasRespondidas, setTodasPerguntasRespondidas] = useState(false);
	const [resultado, setResultado] = useState({});

	useEffect(() => {
		setOpcoesEmbaralhadas(embaralharOpcoes(perguntas[perguntaAtual]));
	}, [perguntaAtual]);

	const handleInputChange = (opcao) => {
		setRespostaSelecionada(opcao);
		setResultados((prevResultados) => {
			const novosResultados = { ...prevResultados };
			novosResultados[opcao]++;
			return novosResultados;
		});

		setTimeout(() => {
			if (perguntaAtual < perguntas.length - 1) {
				setPerguntaAtual((prevPergunta) => prevPergunta + 1);
				setRespostaSelecionada(null);
			} else {
				setResultado({ a: resultados.a * 4, b: resultados.b * 4, c: resultados.c * 4, d: resultados.d * 4 });

				setTodasPerguntasRespondidas(true);
			}
		}, 350);
	};

	return (
		<div className="body">
			{todasPerguntasRespondidas ? (
				<MostrarResultado resultado={resultado} />
			) : (
				<>
					<p className="frame5 alicerce">
						ALICERCE<span className="cor3">3</span>
					</p>
					<div className="frame2">
						<div className="tituloPerguntas"><p className="text2">Assinale a alternativa com a característica que você acredita possuir, ou a que mais se aproxima das suas tendências comportamentais.</p></div>

						<div className="frame4">
							<form>
								{opcoesEmbaralhadas.map((opcao, opcaoIndex) => (
									<label key={`${opcao.label}-${opcaoIndex}`} className="frame8 text2">
										<input
											className="bol"
											type="radio"
											name="opcao"
											value={opcao.value}
											checked={respostaSelecionada === opcao.value}
											onChange={() => handleInputChange(opcao.value)}
										/>
										<div style={{width:'80%',}}>{opcao.label}</div>
									</label>
								))}
							</form>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Perguntas;

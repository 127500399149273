import React, { useContext, useState } from "react";
import "../styles/nome.css";
import { useNavigate } from "react-router-dom";
import NomeContext from "../context/nome";

const Nome = () => {
	const navigate = useNavigate();
	const {nome, setNome} = useContext(NomeContext)
	const [error, setError] = useState("");

	const handleComecarClick = () => {
		setError("");
		if (!isEmpty(nome)) {
			//Navegar para a próxima página se o nome estiver preenchido
			navigate("/perguntas");
		} else {
			setError("Por favor insira o nome para continuar!");
		}
	};

	const isEmpty = (value) => {
		return value.trim() === "";
	};

	return (
		<div className="body">
			<p className="frame5 alicerce">
				ALICERCE<span className="cor3">3</span>
			</p>
			<div className="frame2 text1">
				Vamos começar!
				<div className="frame4">
					{error ? <p className="error">{error}</p> : null}
					<div className="frame6">
						<p className="nomeenv">Nome:</p>
						<input
							type="text"
							className="inp"
							placeholder="Insira o seu nome"
							value={nome}
							onChange={(e) => {
								setNome(e.target.value);
								setError("");
							}}
						/>
					</div>
					<button className="but" onClick={handleComecarClick}>
						Vamos lá
					</button>
				</div>
			</div>
		</div>
	);
};

export default Nome;
